import { Courseversion } from '../payload-types';
import { Flatten } from './typescript';

export const DB_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const PAYMENT_DEADLINE_DAYS_COUNT = 7;
export const ORDER_MAXIMUM_PAYMENT_DETAILS = 2; // maximum amount of payers
export const MAXIMUM_INSTALMENT_SHARES = 2; // maximum amount of instalment shares for courseversion, eg 3 shares and  6 shares

export const JOOSEP_DATE_FORMAT = 'DD.MM.YYYY';
export const JOOSEP_DATE_AND_TIME_FORMAT = `${JOOSEP_DATE_FORMAT} HH:mm:ss`;
// how many days to wait to send order reminder email about payment
export const ORDER_REMINDER_EMAIL_AFTER_DAYS = 3;

// how many minutes after everypay payment has not finished to reset the order:
/**
 * From everypay docs: Note: If the initial callback notification doesn't reach the merchant's server, we'll keep trying to resend it until it's successfully delivered or until it fails permanently. We'll make up to 6 attempts, with intervals of 1 second, 5 minutes, 1 hour, 24 hours, 48 hours, and 72 hours between each attempt. If the callback still fails after these attempts, it will be considered a permanent failure.
 */
export const EVERY_PAY_STUCK_MAX_TIME_MINUTE = 15;

export const ADMIN_UI_DATE_FORMAT = 'dd.MM.yyyy';
export const ADMIN_UI_DATETIME_FORMAT = `${ADMIN_UI_DATE_FORMAT} HH:mm`;

export const INVOICE_DATE_FORMAT = 'DD.MM.YYYY';

export const TIME_EDIT_TIMESTAMP_FORMAT = 'YYYYMMDDTHHMMss';

export const PARTICIPANT_INVITATION_TIMEOUT_DAYS = 7;

export const VAT_MULTIPLIER = 1.22;
export const VAT = 0.22;

/*
1BS*U2B1 - Bakalaureuseõpe ja 2+3
1PBS*U2B1- MBA ja juhtimiskoolitus
1RDI*U2B1 – RDI koolitustulud
1REK*U2B1 – Rektoraadi koolitustulud
1OUT*U2B1 – Välised koolitused
*/
type BudgetDivision = Flatten<Courseversion['budgetDivision']>;
type OsakondValue = BudgetDivision['osakond'];
export type JoosepBudgetDivisionCodes = Record<OsakondValue, string>;
export const JOOSEP_BUDGET_DIVISION_CODES: JoosepBudgetDivisionCodes = {
	baka: '1BS*U2B2',
	postBaka: '1PBS*U2B2',
	rdi: '1RDI*U2B2',
	rektoraat: '1REK*U2B2',
	external: '1OUT*U2B2',
	gymnaasium: '2U2C',
};

export const SIDEMENU_ITEMS_DEPTH = 6;

export const JOOSEP_OBJ_TAHIS_DEFAULT = 'ExEd';
export const JOOSEP_OBJ_TAHIS_GYMNASIUM = 'G';

export const JOOSEP_KONTO_NR_VAT = '26012R' as const;
export const JOOSEP_KONTO_NR_NO_VAT = '260111' as const;
export const JOOSEP_KONTO_NR_GYMNASIUM = '3231D' as const;
